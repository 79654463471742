import React from 'react'
import { graphql } from 'gatsby'
import Home from 'templates/page'

export const pageQuery = graphql`
  query indexPage {
    page: wpPage(databaseId: { eq: 2 }) {
      ...generalPageFragment
    }
  }
`

// eslint-disable-next-line react/jsx-props-no-spreading
const HomeTemplate = (data: any) => <Home {...data} />

export default HomeTemplate
